import React from 'react'
import classes from './Impressum.module.scss'
import ButtonItem from '../../Navigation/NavButtons/ButtonItem'

import { withNamespaces } from 'react-i18next'
import '../../../i18n'

const Impressum = ({ t }) => {
  return (
    <div className={classes.mainContainer}>
      <ButtonItem label={t('Impressum')} link="/policy/Impressum" />
      <ButtonItem label={t('Datenschutz')} link="/policy/Datenschutz" />
      <ButtonItem label={t('Erstinformation')} link="/policy/Erstinformation" />
      <ButtonItem
        label={t('PolitykaPrywatnosci')}
        link="/policy/Nota%20prawna"
      />
    </div>
  )
}

export default withNamespaces()(Impressum)
