import React from 'react'

import { Link } from 'gatsby'

import { SwipeableDrawer } from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import { makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import CssBaseline from '@material-ui/core/CssBaseline'
import Hidden from '@material-ui/core/Hidden'
import NavButtons from './NavButtons/NavButtons'
import Drawer from './Drawer/Drawer'
import Logo from '../UI/Logo/Logo'

const drawerWidth = '70%'

const useStyles = makeStyles(theme => ({
  appBar: {
    background: 'white',
    color: 'black',
    width: '100%',
    height: 80,
    marginTop: 30,
    justifyContent: 'center',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  buttons: {
    marginLeft: 'auto',
  },
}))

const NavbarLogic = props => {
  const { container } = props
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media screen and (minWidth: 1280px': {
              margin: '10%',
            },
          }}
        >
          <Typography variant="h6" noWrap>
            <Link
              to="/"
              style={{
                color: `black`,
                textDecoration: `none`,
              }}
            >
              <Logo />
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Hidden smDown implementation="css" className={classes.buttons}>
            <NavButtons show={true} />
          </Hidden>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            anchor="left"
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Drawer />
          </SwipeableDrawer>
        </Hidden>
      </nav>
    </>
  )
}

const Navbar = props => {
  const showNav = props.showNav
  return (
    <div>
      <NavbarLogic siteTitle={props.siteTitle} showNav={showNav} />
    </div>
  )
}
export default Navbar
