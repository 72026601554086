import React from 'react'
import PropTypes from 'prop-types'
import { animateScroll as scroll } from 'react-scroll'
import IosArrowDropupCircle from 'react-ionicons/lib/IosArrowDropupCircle'

import classes from './layout.module.scss'
import ContactBar from '../../components/ContactBar/ContactBar'
import Navbar from '../../components/Navigation/Navbar'
import Footer from '../../components/Footer/Footer'

const Layout = ({ children }) => {
  return (
    <>
      <div className={classes.App}>
        <ContactBar />
        <Navbar />
        <div className={classes.Main}>
          <main>{children}</main>
        </div>
        <div
          tabIndex={0}
          role="button"
          className={classes.arrowUp}
          onClick={() => scroll.scrollToTop()}
          onKeyDown={() => scroll.scrollToTop()}
        >
          <IosArrowDropupCircle fontSize="50" color="#444444" />
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
