import React, { useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import '../../i18n'
import { graphql, useStaticQuery } from 'gatsby'

import classes from './ContactBar.module.scss'
import LogoFacebook from 'react-ionicons/lib/LogoFacebook'
import LogoInstagram from 'react-ionicons/lib/LogoInstagram'
import IosPhonePortrait from 'react-ionicons/lib/IosPhonePortrait'
import IosMail from 'react-ionicons/lib/IosMail'
import IosHome from 'react-ionicons/lib/IosHome'
import LogoYoutube from 'react-ionicons/lib/LogoYoutube'

const ContactBar = ({ t }) => {
  let data = useStaticQuery(graphql`
    query {
      allDirectusMainpageTranslation {
        edges {
          node {
            ulica
            miasto
            telefon
            email
          }
        }
      }
    }
  `)
  const telefon = `tel:${data.allDirectusMainpageTranslation.edges[0].node.telefon}`
  return (
    <div className={classes.contactBar} style={{ height: 30 }}>
      <div className={classes.contactContent}>
        <div className={classes.contactItems}>
          <IosPhonePortrait
            fontSize="20"
            style={{ margin: 5 }}
            className={classes.icon}
          />
          <a id="hyperlink" href={telefon}>
            {data.allDirectusMainpageTranslation.edges[0].node.telefon}
          </a>
          <IosMail style={{ margin: 5 }} className={classes.icon} />
          <a href={'mailto: '}>
            {data.allDirectusMainpageTranslation.edges[0].node.email}
          </a>
          <IosHome
            fontSize="20"
            style={{ margin: 5 }}
            className={classes.icon}
          />
          <p className={classes.ulica}>
            <a target="_blank" href="https://goo.gl/maps/KGu2531LYec4zoLM6">
              {data.allDirectusMainpageTranslation.edges[0].node.ulica},
              {data.allDirectusMainpageTranslation.edges[0].node.miasto}
            </a>
          </p>
        </div>
        <div className={classes.socialItems}>
          <LogoFacebook style={{ cursor: 'pointer', margin: 5 }} />
          <LogoInstagram style={{ cursor: 'pointer', margin: 5 }} />
          <LogoYoutube style={{ cursor: 'pointer', margin: 5 }} />
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(ContactBar)
