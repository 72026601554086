import React from 'react'
import classes from './NavButtons.module.scss'

import { withNamespaces } from 'react-i18next'
import '../../../i18n'
import { animateScroll as scroll } from 'react-scroll'

import ButtonItem from './ButtonItem'
import TranslatorButtons from '../../Translator/TranslatorButtons'

const NavButtons = ({ t }) => {
  return (
    <div className={classes.mainContainer}>
      <ButtonItem label={t('navUsługi')} onClick={() => scroll.scrollTo(550)} />
      <ButtonItem
        label={t('navAbout')}
        link="/about"
        activeStyle={{ color: '#A67C00' }}
      />
      <ButtonItem
        label={t('navBlog')}
        link="/blog"
        activeStyle={{ color: '#A67C00' }}
      />
      <ButtonItem
        label={t('navKontakt')}
        link="/contact"
        activeStyle={{ color: '#A67C00' }}
      />
      <TranslatorButtons />
    </div>
  )
}

export default withNamespaces()(NavButtons)
