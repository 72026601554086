import React, { useContext } from 'react'
import i18n from '../../i18n'
import classes from './TranslatorButtons.module.scss'
import { GlobalDispatchContext } from '../../context/GlobalContextProvider'

const TranslatorButtons = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }
  const onPolishHandler = () => {
    changeLanguage('pl')
    dispatch({ type: 'POLISH' })
  }
  const onEnglishHandler = () => {
    changeLanguage('en')
    dispatch({ type: 'ENGLISH' })
  }
  const onGermanHandler = () => {
    changeLanguage('de')
    dispatch({ type: 'GERMAN' })
  }

  return (
    <div className={classes.buttonContainer} style={props.style}>
      <div
        onKeyDown={onPolishHandler}
        onClick={onPolishHandler}
        className={classes.buttonPoland}
      ></div>
      <div
        onKeyDown={onEnglishHandler}
        onClick={onEnglishHandler}
        className={classes.buttonEngland}
      ></div>
      <div
        onKeyDown={onEnglishHandler}
        onClick={onGermanHandler}
        className={classes.buttonGerman}
      ></div>
    </div>
  )
}

export default TranslatorButtons
