import React from 'react'
import { withNamespaces } from 'react-i18next'
import '../../../i18n'

import classes from './Drawer.module.scss'
import ButtonItem from '../NavButtons/ButtonItem'
import { animateScroll as scroll } from 'react-scroll'
import TranslatorButtons from '../../Translator/TranslatorButtons'

const Drawer = ({ t }) => {
  return (
    <div className={classes.drawer}>
      <div className={classes.buttons}>
        <TranslatorButtons style={{ marginBottom: 40 }} />
        <ButtonItem
          label={t('navUsługi')}
          onClick={() => scroll.scrollTo(600)}
        />
        <ButtonItem label={t('navAbout')} link="/about" />
        <ButtonItem label={t('navBlog')} link="/blog" />
        <ButtonItem label={t('navKontakt')} link="/contact" />
      </div>
    </div>
  )
}

export default withNamespaces()(Drawer)
