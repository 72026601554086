import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'

import translationPL from './locales/pl/PGPage.json'
import translationEN from './locales/en/PGPage.json'
import translationDE from './locales/de/PGPage.json'

// the translations
const resources = {
  pl: {
    translation: translationPL,
  },
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
}

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pl',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
