import React from 'react'
import classes from './ButtonItem.module.scss'
import { Link } from 'gatsby'

const ButtonItem = props => {
  return (
    <div className={classes.buttonItem}>
      <Link
        onClick={props.onClick}
        className={classes.button}
        to={props.link}
        style={{
          color: `#636363`,
          textDecoration: `none`,
          minWidth: 100,
        }}
        activeStyle={props.activeStyle}
      >
        {props.label}
      </Link>
    </div>
  )
}

export default ButtonItem
