import React from 'react'
import classes from './Footer.module.scss'
import Grid from '@material-ui/core/Grid'
import { withNamespaces } from 'react-i18next'
import '../../i18n'
import { animateScroll as scroll } from 'react-scroll'

import ButtonItem from '../Navigation/NavButtons/ButtonItem'
import Impressum from './Impressum/Impressum'
import LogoInstagram from 'react-ionicons/lib/LogoInstagram'
import LogoFacebook from 'react-ionicons/lib/LogoFacebook'
import LogoYoutube from 'react-ionicons/lib/LogoYoutube'

const Footer = ({ t }) => {
  return (
    <div className={classes.footer}>
      <Grid container className={classes.footerContainer}>
        <Grid item xs={12} sm={6}>
          <div className={classes.footerContent}>
            <ButtonItem
              label={t('navUsługi')}
              onClick={() => scroll.scrollTo(600)}
            />
            <ButtonItem label={t('navAbout')} link="/about" />
            <ButtonItem label={t('navBlog')} link="/blog" />
            <ButtonItem label={t('navKontakt')} link="/contact" />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.footerContent}>
            <Impressum />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.socialContent}>
          <div>
            <LogoFacebook style={{ cursor: 'pointer', margin: 10 }} />
            <LogoInstagram style={{ cursor: 'pointer', margin: 10 }} />
            <LogoYoutube style={{ cursor: 'pointer', margin: 10 }} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default withNamespaces()(Footer)
